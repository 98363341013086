import Sidebar from "./Sidebar";
import React, { useCallback, useRef, useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { getToken } from "../helpers/auth";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import JoditEditor from "jodit-react";
import { useNavigate } from "react-router-dom";

export default function AD_Press() {
  const navigate = useNavigate();
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [placeholder, setPlaceholder] = useState();
  const config = useCallback(
    {
      readonly: false, // all options from https://xdsoft.net/jodit/doc/,
      placeholder: placeholder || "Start typings...",
    },
    [placeholder]
  );
  const cancelButtonRef = useRef(null);
  const [data, setData] = useState([]);
  const [addModal, setAddModal] = useState(false);
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/blogs`, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {});
  };

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    file: "",
  });

  const { title, file } = formData;

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", content);
    formData.append("file", file);
    axios
      .post(`${process.env.REACT_APP_API_URL}/user/add/blog`, formData, {
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((result) => {
        const new_blog = result.data;
        setData([...data, new_blog]);
        setAddModal(false);
      })
      .catch((err) => {});
  };

  const handleChange = (text) => (e) => {
    setFormData({ ...formData, [text]: e.target.value });
  };

  const [isImageSelected, setIsImageSelected] = useState(false);
  const [imagePreviewSrc, setImagePreviewSrc] = useState("");

  const showImagePreview = (e) => {
    let selectedFile = e.target.files.item(0);
    if (selectedFile) {
      if (
        ["image/jpeg", "image/png", "image/svg+xml"].includes(selectedFile.type)
      ) {
        let fileReader = new FileReader();
        fileReader.readAsDataURL(selectedFile);
        fileReader.addEventListener("load", (event) => {
          setImagePreviewSrc(event.target.result);
          setFormData({ ...formData, ["file"]: e.target.files.item(0) });
          setIsImageSelected(true);
        });
      }
    } else {
      setIsImageSelected(false);
    }
  };

  return (
    <div>
      <div className={`bg-[#f4f2eb] relative  md:flex flex-row `}>
        <Sidebar />
        <div className={`2xl:container 2xl:mx-auto pt-6 w-full`}>
          <div className={`flex flex-row justify-end px-6`}>
            <svg
              onClick={() => {
                setAddModal(true);
              }}
              className={`w-12 h-12 `}
              viewBox="0 0 159 159"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M108.07 74.5312H84.4688V50.9297C84.4688 50.2465 83.9098 49.6875 83.2266 49.6875H75.7734C75.0902 49.6875 74.5312 50.2465 74.5312 50.9297V74.5312H50.9297C50.2465 74.5312 49.6875 75.0902 49.6875 75.7734V83.2266C49.6875 83.9098 50.2465 84.4688 50.9297 84.4688H74.5312V108.07C74.5312 108.754 75.0902 109.312 75.7734 109.312H83.2266C83.9098 109.312 84.4688 108.754 84.4688 108.07V84.4688H108.07C108.754 84.4688 109.312 83.9098 109.312 83.2266V75.7734C109.312 75.0902 108.754 74.5312 108.07 74.5312Z"
                fill="#808080"
              />
              <path
                d="M79.5 9.9375C41.0854 9.9375 9.9375 41.0854 9.9375 79.5C9.9375 117.915 41.0854 149.062 79.5 149.062C117.915 149.062 149.062 117.915 149.062 79.5C149.062 41.0854 117.915 9.9375 79.5 9.9375ZM79.5 137.262C47.6068 137.262 21.7383 111.393 21.7383 79.5C21.7383 47.6068 47.6068 21.7383 79.5 21.7383C111.393 21.7383 137.262 47.6068 137.262 79.5C137.262 111.393 111.393 137.262 79.5 137.262Z"
                fill="#808080"
              />
            </svg>
          </div>

          <Transition.Root show={addModal} as={Fragment}>
            <Dialog
              as="div"
              className="fixed z-30 inset-0 overflow-y-auto"
              initialFocus={cancelButtonRef}
              onClose={(e) => {
                setAddModal(e);
              }}
            >
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-150"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-150"
                  leaveFrom="opacity-70"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 backdrop-filter backdrop-blur-sm bg-gray-300 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-150"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className="inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-lg transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
                    <div className="bg-[#f4f2eb] relative w-full py-10 ">
                      <form
                        onSubmit={handleSubmit}
                        className={`py-4 xl:mx-auto w-full  px-6 sm:px-8 lg:px-12 `}
                      >
                        <input
                          type="text"
                          id="titlu"
                          placeholder="Titlu"
                          value={title}
                          onChange={handleChange("title")}
                          className="rounded-xl focus:outline-none w-full mb-4  border border-lightgray text-2xl py-2 px-3 bg-transparent font-teko"
                        />
                        <JoditEditor
                          ref={editor}
                          value={content}
                          config={config}
                          tabIndex={1} // tabIndex of textarea
                          onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                          onChange={(newContent) => {}}
                        />
                        <div
                          className={`flex flex-col md:flex-row justify-between pt-6 space-y-4`}
                        >
                          <input
                            className={``}
                            type="file"
                            multiple
                            onChange={showImagePreview}
                            id="file"
                            required
                          />
                          {isImageSelected ? (
                            <img
                              className={`w-40 h-40`}
                              src={imagePreviewSrc}
                              alt=""
                            />
                          ) : null}
                        </div>
                        <div className={`flex flex-row place-content-end`}>
                          {" "}
                          <button
                            type="submit"
                            className={`mt-4 py-1.5 px-6 bg-primary text-white rounded-xl`}
                          >
                            Add blog
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          <div
            className={` flex flex-row flex-wrap justify-center gap-8 p-6 md:p-4 lg:p-16`}
          >
            {data.map((data) => (
              <div key={data._id} className={`w-full xs:w-72 relative`}>
                <div
                  className={`absolute bg-primary text-center font-teko text-white w-[65px] xl:w-[75px] py-1 xl:py-2 top-[100px] left-4 xl:left-6`}
                >
                  <h1 className={`text-2xl xl:text-3xl`}>{data.date}</h1>
                  <h1 className={`uppercase text-base xl:text-lg -mt-2`}>
                    {data.month}
                  </h1>
                </div>
                <div className={` bg-cover bg-center h-36 xl:h-40`} style={{ backgroundImage: `url(${data.image})` }}>
                </div>
                <div className={`h-40 xl:h-44 bg-[#ece8de] p-5 xl:p-7`}>
                  <h1 className={`font-teko text-darker text-lg xl:text-xl`}>
                    {data.title}
                  </h1>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: data.description,
                    }}
                    className={`text-[11px] xl:text-xs  line-clamp-3 text-darker`}
                  ></p>
                  <button
                    onClick={() => {
                      navigate(`/admin/blog/${data._id}`);
                    }}
                    className={`mt-3 xl:mt-5 flex flex-row items-center font-bold text-[11px] xl:text-xs text-primary`}
                  >
                    <h1>EDIT</h1>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-1  h-3 w-3 "
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13 5l7 7-7 7M5 5l7 7-7 7"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
