import React, {useState} from "react";

const Pagination = ({ photosPerPage, totalPhotos, paginate, currentPage}) => {
    const pageNumbers = [];

    for(let i = 1; i <= Math.ceil(totalPhotos / photosPerPage); i++) {
        pageNumbers.push(i);
    }

const [selectedPage, setSelectedPage] = useState(1)

    return (
        <nav>
            <ul className={` flex flex-row space-x-2 text-xs font-semibold text-dark justify-center`}>
                <div onClick={() => {
                    if (currentPage>1) { currentPage-- ;
                    paginate(currentPage)};
                }}
                    className={`${currentPage == 1 ? "text-gray-300 border-gray-300" : "text-dark" } cursor-pointer flex items-center justify-center border border-dark w-8 h-8`}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3 w-3"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 19l-7-7 7-7"
                        />
                    </svg>
                </div>
                {pageNumbers.map(number => (
                    <li key={number} onClick={() =>
                        paginate(number)}
                        className={`${ currentPage == number? 'border-primary bg-primary text-white ' : 'border-dark' } cursor-pointer flex items-center justify-center border  w-8 h-8`}>

                            {number}

                    </li>
                ))}
                <div
                    onClick={() => {
                        if (currentPage < pageNumbers.length) { currentPage++ ;
                            paginate(currentPage)};
                    }}
                    className={`${currentPage == pageNumbers.length ? "text-gray-300 border-gray-300" : "text-dark" }  cursor-pointer flex items-center justify-center border border-dark w-8 h-8`}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-3 w-3"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 5l7 7-7 7"
                        />
                    </svg>
                </div>
            </ul>
        </nav>
    )
}

export default Pagination