import Navbar from "./Navbar";
import Footer from "./Footer";
import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import Pagination from "./helpers/Pagination";
import React from "react";
import logo from "../img/logo.svg";
import ImageViewer from 'react-simple-image-viewer';

export default function Gallery() {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [photosPerPage] = useState(9);
  const [categories, setCategories] =useState([]);
  const [categoryId, setCategoryId] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
    const [images, setImages] = useState();
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

  useEffect(() => {
    fetchPhotos();
  }, []);

  const fetchPhotos = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/gallery`, {})
      .then((res) => {
        setPhotos(res.data.gallery);
        setCategories(res.data.gallery_categories);
        setSelectedCategory(res.data.gallery_categories[0]._id);
        setLoading(false);
          let get_images =[];
          for(let photo of res.data.gallery){
              get_images.push(photo.image);
          }

          setImages(get_images)
      })
      .catch((err) => {});
  };

  const getCategoryPhotos = (categoryId) => {
    axios
        .get(`${process.env.REACT_APP_API_URL}/gallery/${categoryId}`, {})
        .then((res) => {
          setPhotos(res.data);
          setLoading(false);
        })
        .catch((err) => {});
  };

  //get current photos
  const indexOfLastPhoto = currentPage * photosPerPage;
  const indexOfFirstPhoto = indexOfLastPhoto - photosPerPage;
  const currentPhotos = photos.slice(indexOfFirstPhoto, indexOfLastPhoto);

  //change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);



  return (
    <div>
      <Navbar />

      <div className="pt-20">
          {/*header*/}
          <div
              className={`relative -z-20 bg-black flex flex-col mx-auto items-center py-12 s:py-16 sm:py-20 xl:py-24 space-y-3 xl:space-y-5`}
          >
              <img
                  className={`-z-10 opacity-20 absolute w-24 w-[150px] lg:w-[200px] xl:w-[300px] top-1/2 -translate-y-1/2`}
                  src={logo}
                  alt=""
              />

              <h1
                  className={`z-0 uppercase text-white font-teko text-center text-5xl xl:text-6xl`}
              >
                  GALLERY GRID
              </h1>
          </div>

          {/*gallery section*/}
          <div className={`relative`}>
              <div
                  className={`2xl:container px-4 xs:px-6 py-12  lg:py-20 xl:py-24 mx-auto flex flex-col items-center`}
              >
                  <ul
                      className={`flex flex-wrap justify-center gap-4 uppercase text-dark font-semibold text-sm`}
                  >
                      {categories.map((categories) => (
                          <li  key={categories._id} className={`${selectedCategory == categories._id ? 'text-primary font-bold' : null }`} onClick={() =>{
                              getCategoryPhotos(categories._id);
                              setSelectedCategory(categories._id);
                          }}>{categories.name}</li>
                      ))}
                  </ul>
                  <div className={`py-10`}>
                      {loading ? (
                          <h2> Loading...</h2>
                      ) : (
                          <ul
                              className={`grid grid-cols-1 s:grid-cols-2 md:grid-cols-3 mb-4 gap-4`}
                          >
                              {currentPhotos.map((photo, index) => (

                                  <li
                                      className={
                                          "relative group"
                                      }
                                      key={photo._id}

                                  >
                                      <img className={`w-64 h-64 s:h-52 s:w-52 sm:w-64 sm:h-64 md:h-52 md:w-52 lg:w-64 lg:h-64 object-cover`}  key={index}  src={photo.image} alt=""/>

                                      <div
                                          onClick={ () => openImageViewer(index) }
                                          className={` top-0 absolute w-64 h-64 bg-black z-0 bg-opacity-60 invisible group-hover:visible flex items-end`}
                                      >
                                          <div
                                              className={`bg-primary w-8 h-8 flex items-center justify-center`}
                                          >
                                              <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  className="h-4 w-4"
                                                  viewBox="0 0 24 24"
                                                  stroke="#fff"
                                                  strokeWidth={2}
                                              >
                                                  <path
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                      d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
                                                  />
                                              </svg>

                                          </div>
                                      </div>
                                      {isViewerOpen && (
                                          <ImageViewer
                                              src={ images }
                                              currentIndex={ currentImage }
                                              disableScroll={ false }
                                              closeOnClickOutside={ true }
                                              onClose={ closeImageViewer }
                                          />
                                      )}

                                  </li>
                              ))}

                          </ul>
                      )}


                  </div>
                  <Pagination
                      photosPerPage={photosPerPage}
                      totalPhotos={photos.length}
                      paginate={paginate}
                      currentPage={currentPage}
                  />
              </div>
          </div>
      </div>
      <Footer />
    </div>
  );
}
