import Navbar from "./Navbar";
import Footer from "./Footer";
import manu from "../img/manu_1.jpg";
import blackM from "../img/blackM.png";
import mustache from "../img/mustache.png";
import pic from "../img/handsome-man-barber-shop-styling-hair.jpg";
import Reviews from "./Reviews";
import React from "react";
import logo from "../img/logo.svg";
import OurBarbers from "./helpers/OurBarbers";

export default function About() {
  return (
    <div>
      <Navbar />

      {/*header*/}
      <div className="pt-20">
        <div
            className={`relative -z-20 bg-black flex flex-col mx-auto items-center py-12 s:py-16 sm:py-20 xl:py-24 space-y-3 xl:space-y-5`}
        >
          <img className={`-z-10 opacity-20 absolute w-24 w-[150px] lg:w-[200px] xl:w-[300px] top-1/2 -translate-y-1/2`} src={logo} alt="" />

          <h1
              className={`z-0 uppercase text-white font-teko text-center text-5xl xl:text-6xl`}
          >
            ABOUT US
          </h1>
        </div>

        {/*section 1*/}
        <div
            className={`py-6 px-4 xs:px-6 sm:px-20 lg:py-8 xl:py-12`}
        >
          <div
              className={`2xl:container flex flex-col items-center text-center text-darker mx-auto py-4 lg:py-8 xl:py-12 space-y-3 xl:space-y-5 relative`}
          >

            <h1 className={`font-teko text-4xl xl:text-5xl font-medium`}>
              OUR PHILOSOPHY
            </h1>
            <img className={`w-40 xl:w-48`} src={blackM} alt="" />
            <p
                className={`md:w-2/3 lg:w-1/2 text-xs xl:text-sm leading-[1.5rem] lg:leading-[2rem] xl:leading-[2.5rem]`}
            >
              Jäger Barbershop exudes a hipster modern vibe, where sleek industrial design seamlessly blends with rustic elements. The ambient lighting casts a warm glow over exposed brick walls adorned with vintage barber tools. Edgy, minimalist decor complements the modern -inspired furniture, creating a space that feels both cutting-edge and inviting. Our skilled barbers provide tailored grooming experiences, ensuring every visit is a fusion of style and relaxation. Welcome to Jäger Barbershop, where tradition meets contemporary cool.
            </p>
          </div>
        </div>

        {/*section 2*/}
        <div className={`bg-[#424242] py-16 sm:py-24 xl:py-32`}>
          <div
              className={`2xl:container mx-auto flex flex-col  sm:space-y-8 xl:space-y-12 items-center justify-center uppercase text-white text-center`}
          >
            <img className={`w-16 sm:w-24 xl:w-32`} src={mustache} />
            <h1
                className={`mt-6 sm:mt-0 px-8  font-teko text-4xl sm:text-4xl xl:text-4xl sm:leading-[3.5rem] xl:leading-[4.5rem]`}
            >
              At Jäger Barbershop, being a barber is more than cutting hair. It is an art of care, creating personalized styles that go beyond grooming.
            </h1>
          </div>
        </div>

        {/*section 3*/}
        <div className={``}>
          <div className={`py-12 lg:py-20 xl:py-24 p-4 xs:px-6 sm:px-20 md:px-0`}>
            <div
                className={`flex flex-col items-center text-center mx-auto space-y-3`}
            >
              <h1
                  className={`font-teko text-4xl xl:text-5xl font-medium text-darker`}
              >
                OUR BARBER
              </h1>
              <img className={`w-40 xl:w-48`} src={blackM} alt="" />
            </div>

            <div
                className={`flex flex-col flex-wrap md:flex-nowrap s:flex-row mx-auto justify-center items-center space-y-6 s:space-y-0 s:gap-4 sm:gap-8 md:gap-0 md:space-x-4 xl:space-x-6 mt-6 xs:mt-8 lg:mt-10 xl:mt-16`}
            >

              <OurBarbers/>

            </div>
          </div>
        </div>

        {/*section 4*/}
        <div className={`hidden bg-[#f4f2eb]`}>
          <div className={`py-12 lg:py-20 xl:py-24 `}>
            <div
                className={` px-4 xs:px-6 sm:px-20 flex flex-col items-center text-center mx-auto space-y-3 xl:space-y-5`}
            >
              <h1
                  className={`font-teko text-5xl xl:text-6xl font-medium text-darker`}
              >
                WHAT OUR CLIENT SAYS
              </h1>
              <img className={`w-40 xl:w-48`} src={blackM} alt="" />
              <p
                  className={`s:w-9/12 lg:w-5/12  text-xs xl:text-sm text-darker leading-loose xl:leading-[2rem]`}
              >
                Aores autem accusamus magni doloribus qui nostrum ex tenetur dicta
                rem eveniet ut soluta voluptates inventore. Ipsum dolorum fugiat
                sint.
              </p>
            </div>

            <div className={`2xl:container mx-auto px-4 xs:px-6 sm:px-20 `}>
              <Reviews />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
