import {isAuth} from "../helpers/auth";
import React from 'react';
import { Navigate, Outlet, Route } from 'react-router-dom';


 const AdminRoute = () => {
     return (isAuth() && isAuth().name === 'Admin')  ? <Outlet /> : <Navigate to="/login" />;

 }

 export default AdminRoute;


