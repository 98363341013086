import Sidebar from "./Sidebar";
import { useEffect, useState } from "react";
import axios from "axios";
import { getToken } from "../helpers/auth";

export default function AD_Dashboard() {
  const [data, setData] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/dashboard`, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {});
  };

  return (
    <div>
      <div className={`bg-[#f4f2eb] relative h-screen md:flex flex-row `}>
        <Sidebar />
        <div className={`2xl:container 2xl:mx-auto`}>
          <div
            className={`flex flex-col s:flex-row space-y-4 s:space-y-0 s:w-full s:space-x-4 sm:space-x-8 md:space-x-6 p-4 s:p-6 sm:px-8 sm:pt-12 md:px-12 2xl:pl-56 `}
          >
            <div
              className={`flex flex-col justify-center md:w-48 md:h-20 sm:space-y-2 md:space-y-0 font-bold border border-2 border-primary rounded-2xl bg-transparent py-2 px-4 sm:py-4 sm:px-6 md:px-4 w-full`}
            >
              <h1 className={`text-primary text-xl sm:text-2xl md:text-lg`}>
                Blogs
              </h1>
              <h2 className={`text-lightgray text-lg sm:text-xl md:text-base`}>
                {data.blogs}
              </h2>
            </div>
            <div
              className={`flex flex-col justify-center md:w-48 md:h-20 sm:space-y-2 md:space-y-0 font-bold border border-2 border-primary rounded-2xl bg-transparent py-2 px-4 sm:py-4 sm:px-6 md:px-4 w-full`}
            >
              <h1 className={`text-primary text-xl sm:text-2xl md:text-lg`}>
                Photos
              </h1>
              <h2 className={`text-lightgray text-lg sm:text-xl  md:text-base`}>
                {data.galleries}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
