import Navbar from "./Navbar";
import Footer from "./Footer";
import {useEffect, useState} from "react";
import axios from "axios";
import {getToken} from "./helpers/auth";
import React from "react";
import {useNavigate} from "react-router-dom";
import logo from "../img/logo.svg";

export default function Press() {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/blogs`, {})
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
            });
    };

    return (
        <div>
            <Navbar/>

            <div className="pt-20">
                {/*header*/}
                <div
                    className={`relative -z-20 bg-black flex flex-col mx-auto items-center py-12 s:py-16 sm:py-20 xl:py-24 space-y-3 xl:space-y-5`}
                >
                    <img
                        className={`-z-10 opacity-20 absolute w-24 w-[150px] lg:w-[200px] xl:w-[300px] top-1/2 -translate-y-1/2`}
                        src={logo}
                        alt=""
                    />

                    <h1
                        className={`z-0 uppercase text-white font-teko text-center text-5xl xl:text-6xl`}
                    >
                        our press
                    </h1>
                </div>

                {/*press section*/}
                <div className={`relative`}>
                    <div
                        className={`2xl:container mx-auto py-12 lg:py-20 xl:py-24  flex flex-col sm:flex-row justify-center sm:px-8  xl:px-16 sm:gap-8 md:gap-6   xl:gap-20 `}
                    >

                        {/*articles*/}
                        <div
                            className={`grid grid-cols-1 s:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 px-4 xs:px-6 sm:px-0   gap-6 md:gap-10 items-center `}
                        >
                            {data.map((data) => (
                                <div key={data._id} className={`w-full relative`}>
                                    <div
                                        className={`absolute bg-primary text-center font-teko text-white w-14 lg:w-16 py-1.5 xl:py-2  top-[115px] lg:top-[140px] xl:top-[170px] left-4 lg:left-6`}
                                    >
                                        <h1 className={`text-2xl lg:text-3xl `}>25</h1>
                                        <h1 className={`text-base lg:text-lg -mt-2`}>AUGUST</h1>
                                    </div>
                                    <div className={`bg-cover bg-center h-40 lg:h-48 xl:h-56 `}
                                         style={{backgroundImage: `url(${data.image})`}}>
                                    </div>
                                    <div className={`h-40 xl:h-60 py-6 lg:py-8`}>
                                        <h1
                                            className={`uppercase font-teko text-darker text-2xl lg:text-4xl`}
                                        >
                                            {data.title}
                                        </h1>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: data.description,
                                            }}
                                            className={`hide_press mt-2 lg:mt-4 text-xs lg:text-sm line-clamp-3 text-[#585858] font-semibold`}
                                        />
                                        <button
                                            onClick={() => {
                                                navigate(`/blog/${data._id}`);
                                            }}
                                            className={`mt-3 flex flex-row items-center font-bold text-[11px] lg:text-sm text-primary`}
                                        >
                                            <h1>READ MORE</h1>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="ml-1  h-3 w-3 lg:h-4 lg:w-4"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                strokeWidth={2}
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M13 5l7 7-7 7M5 5l7 7-7 7"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}
