import logo from "../img/logo.svg";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useLayoutEffect } from "react";

export default function Navbar() {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => {
    setSidebar(!sidebar);
  };

  const [isDesktop, setDesktop] = useState(window.innerWidth >= 1024);

  const updateMedia = () => {
    setDesktop(window.innerWidth >= 1024);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  useEffect(() => {
    if (sidebar) {
      document.body.style.overflow = "hidden";
    }
    if (isDesktop || !sidebar) {
      document.body.style.overflow = "unset";
    }
  }, [sidebar, isDesktop]);

  return (
    <div className={`fixed inset-x-0 z-20 bg-[#1a1a1a] flex flex-row items-center justify-between`}>
      <div
        className={`w-full 2xl:container mx-auto flex flex-row justify-between leading-[0px] lg:px-8 xl:px-12 items-center `}
      >
        <div
          className={`relative w-full flex flex-row items-center justify-center lg:justify-start`}
        >
          <div className={`w-24 s:w-[9.5rem] md:w-48 flex items-center`}><button
              onClick={showSidebar}
              className={`absolute left-4 xs:left-6 sm:left-8 block lg:hidden `}
          >
            <svg
                className={`w-6 s:w-8`}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <g id="24 / basic / text-align-left">
                <path
                    id="icon"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22 7V5H2V7H22ZM16 9V11H2V9H16ZM22 15V13H2V15H22ZM16 19V17H2V19H16Z"
                    fill="white"
                />
              </g>
            </svg>
          </button></div>

          <div className={`w-24 s:w-[9.5rem] md:w-48 lg:w-auto`}>
            <Link to="/"><img className={`w-20 2xl:ml-24 mx-auto`} src={logo} alt="" /></Link>
          </div>
        </div>
        {sidebar ? (
          <div
            className={` z-10 lg:hidden fixed inset-0 backdrop-filter backdrop-blur-sm bg-gray-400 bg-opacity-70 transition-opacity`}
            onClick={showSidebar}
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              className=" lg:hidden absolute px-6 sm:px-8 py-4 top-0 right-0 h-screen w-full bg-[#1a1a1a]/70"
            >
              <div className="modal-header flex flex-row justify-end p-2">
                <button
                  type="button"
                  className="mt-1"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={showSidebar}
                >
                  <svg
                    className={`w-4 s:w-5`}
                    viewBox="0 0 17 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.49997 10.3575L2.60021 15.8456L0.0664062 13.4886L5.96617 8.00045L0.0664062 2.5123L2.60021 0.155273L8.49997 5.64343L14.3997 0.155273L16.9335 2.5123L11.0338 8.00045L16.9335 13.4886L14.3997 15.8456L8.49997 10.3575Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
              <nav className="flex mt-10 sm:mt-16 pl-2 ">
                <ul
                  className={`flex flex-col text-white font-semibold text-xl s:text-2xl sm:text-3xl space-y-6 s:space-y-8`}
                >
                  <Link to="/">
                    {" "}
                    <li>Home</li>
                  </Link>
                  <Link to="/about">
                    <li>About</li>
                  </Link>
                  <Link to="/gallery">
                    <li>Gallery</li>
                  </Link>
                  <Link to="/press">
                    <li>Press</li>
                  </Link>
                  <Link to="/contact">
                    <li>Contact</li>
                  </Link>

                </ul>
              </nav>
            </div>
          </div>
        ) : null}

        <nav className={`hidden lg:block`}>
          <ul
            className={`font-teko flex flex-row text-white space-x-4 xl:text-lg`}
          >
            <Link to="/">
              <li
                className={`px-4 py-[26px] border-b-2 border-[#1a1a1a] hover:border-primary hover:text-primary transition-all duration-300`}
              >
                HOME
              </li>
            </Link>
            <Link to="/about">
              <li
                className={`px-4 py-[26px]  border-b-2 border-[#1a1a1a] hover:border-primary hover:text-primary transition-all duration-300`}
              >
                ABOUT
              </li>
            </Link>
            <Link to="/gallery">
              <li
                className={`px-4 py-[26px]  border-b-2 border-[#1a1a1a] hover:border-primary hover:text-primary transition-all duration-300`}
              >
                GALLERY
              </li>
            </Link>
            <Link to="/press">
              <li
                className={`px-4 py-[26px]  border-b-2 border-[#1a1a1a] hover:border-primary hover:text-primary transition-all duration-300`}
              >
                PRESS
              </li>
            </Link>
            <Link to="/contact">
              <li
                className={`px-4 py-[26px] border-b-2 border-[#1a1a1a] hover:border-primary hover:text-primary transition-all duration-300`}
              >
                CONTACT
              </li>
            </Link>
          </ul>
        </nav>
      </div>
      <a href="https://phorest.com/book/salons/jaegerbarbershop" target="_blank">
        <button
            id="highlightButton"
          className={`highlight flex flex-row justify-center items-center px-0.5 py-1.5 xl:py-2 mr-4 s:mr-6 md:mr-8 lg:mx-10  rounded-full text-white font-medium text-sm md:text-base xl:text-lg w-24 s:w-32 md:w-40 xl:w-44 font-teko bg-[#B6816F] opacity-90 shadow-[#B6816F] shadow-lg`}
        >
          <h1 className="text-center mt-1">BOOK APPOINTMENT</h1>
        </button>
      </a>
    </div>
  );
}
