import React from "react";

export const isAuth = () => {
    const user = localStorage.getItem('User')

    if (!user) {
        return;
    }

    return JSON.parse(user);
}

export const getToken = () => {
    const user = localStorage.getItem('User')

    if (!user) {
        return;
    }

    return JSON.parse(user).token;
}
