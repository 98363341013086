import Navbar from "./Navbar";
import Footer from "./Footer";
import blackM from "../img/blackM.png";
import chair from "../img/chair.png";
import logo from "../img/logo.svg";
import React from "react";

export default function Appointment() {
  return (
    <div>
      <Navbar />

      {/*header*/}
      <div
          className={`relative -z-20 bg-black flex flex-col mx-auto items-center py-12 s:py-16 sm:py-20 xl:py-24 space-y-3 xl:space-y-5`}
      >
        <img className={`-z-10 opacity-20 absolute w-24 w-[150px] lg:w-[200px] xl:w-[300px] top-1/2 -translate-y-1/2`} src={logo} alt="" />

        <h1
            className={`z-0 uppercase text-white font-teko text-center text-5xl xl:text-6xl`}
        >
          APPOINTMENT
        </h1>
      </div>

      {/*section 1*/}
      <div className={`bg-[#f4f2eb] relative`}>
        <img
            className={`hidden md:block absolute right-0 md:h-3/5 xl:h-4/6 2xl:h-5/6 top-1/2 -translate-y-1/2`}
          src={chair}
          alt=""
        />
        <div className={`2xl:container mx-auto py-12 lg:py-20 xl:py-24`}>
          <div
            className={`p-4 xs:px-6 sm:px-20 flex flex-col items-center text-center mx-auto space-y-3 xl:space-y-5`}
          >
            <h1
              className={`font-teko text-5xl xl:text-6xl font-medium text-darker`}
            >
              MAKE AN APPOINTMENT
            </h1>
            <img className={`w-40 xl:w-48`} src={blackM} alt="" />

          </div>

          <div
            className={`w-full mt-4 sm:mt-0 lg:mt-4 flex flex-col items-center relative`}
          >
            <a href="tel:+4318908780">
            <h1
              className={`rotate-[270deg] hidden sm:block uppercase font-teko font-medium absolute sm:-left-8 md:left-0  top-[35%] text-2xl xl:text-[27px]`}
            >
              call us: +1-438-861-0045
            </h1></a>
            <div
              className={`w-4/5 sm:w-3/5 flex flex-col items-center mx-auto space-y-4 xl:space-y-6`}
            >
              <a href="tel:+4318908780">
              <h1
                className={`sm:hidden uppercase font-teko font-medium  text-2xl`}
              >
                call us: +1-438-861-0045
              </h1> </a>
              <div
                className={`flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-8 xl:space-x-10 w-full`}
              >
                <div className={`w-full  border border-darker`}>
                  <input
                    className={`w-full focus:outline-none bg-transparent py-2.5 xl:py-3 pl-4 text-[10px] xl:text-xs placeholder:text-[#787675] text-[#787675]`}
                    placeholder={`YOUR NAME`}
                  />
                </div>
                <div className={`w-full  border border-darker`}>
                  <input
                    className={`w-full focus:outline-none bg-transparent py-2.5 xl:py-3 pl-4 text-[10px] xl:text-xs placeholder:text-[#787675] text-[#787675]`}
                    placeholder={`EMAIL ADRESS`}
                  />
                </div>
              </div>
              <div
                className={`flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-8 w-full`}
              >
                <div className={`w-full  border border-darker`}>
                  <input
                    className={`w-full focus:outline-none bg-transparent py-2.5 xl:py-3 pl-4 text-[10px] xl:text-xs placeholder:text-[#787675] text-[#787675]`}
                    placeholder={`PHONE NUMBER`}
                  />
                </div>
                <div className={`w-full  border border-darker`}>
                  <input
                    className={`w-full focus:outline-none bg-transparent py-2.5 xl:py-3 pl-4 text-[10px] xl:text-xs placeholder:text-[#787675] text-[#787675]`}
                    placeholder={`APPOINTMENT DATE`}
                  />
                </div>
              </div>
              <div className={`w-full h-24 border border-darker`}>
                <textarea
                  className={`w-full h-full focus:outline-none bg-transparent py-2.5 xl:py-3 pl-4 text-[10px] xl:text-xs placeholder:text-[#787675] text-[#787675]`}
                  placeholder={`YOUR MESSAGE...`}
                />
              </div>
            </div>
            <button
              className={`mt-6 xl:mt-8 mx-auto bg-primary text-white font-bold text-xs xl:text-sm px-5 xl:px-6 py-3 xl:py-3.5 `}
            >
              MAKE AN APPOINTMENT
            </button>
          </div>
        </div>
      </div>

      {/*section 2*/}
      <div className={`bg-[#272727]`}>
        <div
          className={`2xl:container mx-auto py-12 lg:py-16 xl:py-20 flex flex-col items-center uppercase`}
        >
          <h1
            className={`text-white font-teko text-5xl xl:text-6xl font-medium `}
          >
            working hours
          </h1>
          <a href="tel:+4318908780">
            <h2
                className={`text-white font-teko text-3xl lg:text-5xl xl:text-6xl mt-4`}
            >
              <span className={`text-primary`}>call us:</span> +4318908780
            </h2> </a>
          <div
            className={`grid grid-cols-2 s:grid-cols-3 gap-3 s:gap-4 md:gap-6 lg:gap-0 lg:flex lg:flex-row lg:space-x-4 xl:space-x-6 mt-8 lg:mt-10 xl:mt-14`}
          >

            <div
              className={`text-center rounded-lg border-2 border-primary text-white px-2.5 xl:px-3.5 py-3 xl:py-4 bg-[#2a2a2a]`}
            >
              <h1 className={`text-3xl xl:text-4xl font-teko`}>TUES</h1>
              <h2 className={`text-[11px] xl:text-xs font-bold`}>
                6.00 AM - 6.30 PM
              </h2>
            </div>
            <div
              className={`text-center rounded-lg border-2 border-primary text-white px-2.5 xl:px-3.5 py-3 xl:py-4 bg-[#2a2a2a]`}
            >
              <h1 className={`text-3xl xl:text-4xl font-teko`}>WED</h1>
              <h2 className={`text-[11px] xl:text-xs font-bold`}>
                6.00 AM - 6.30 PM
              </h2>
            </div>
            <div
              className={`text-center rounded-lg border-2 border-primary text-white px-2.5 xl:px-3.5 py-3 xl:py-4 bg-[#2a2a2a]`}
            >
              <h1 className={`text-3xl xl:text-4xl font-teko`}>THUR</h1>
              <h2 className={`text-[11px] xl:text-xs font-bold`}>
                6.00 AM - 6.30 PM
              </h2>
            </div>
            <div
              className={`text-center rounded-lg border-2 border-primary text-white px-2.5 xl:px-3.5 py-3 xl:py-4 bg-[#2a2a2a]`}
            >
              <h1 className={`text-3xl xl:text-4xl font-teko`}>FRI</h1>
              <h2 className={`text-[11px] xl:text-xs font-bold`}>
                6.00 AM - 6.30 PM
              </h2>
            </div>
            <div
              className={`text-center rounded-lg border-2 border-primary text-white px-2.5 xl:px-3.5 py-3 xl:py-4 bg-[#2a2a2a]`}
            >
              <h1 className={`text-3xl xl:text-4xl font-teko`}>SAT</h1>
              <h2 className={`text-[11px] xl:text-xs font-bold`}>
                6.00 AM - 6.30 PM
              </h2>
            </div>
          </div>

          <h3
              className={`text-center text-white font-teko text-3xl xl:text-4xl mt-6 lg:mt-12 xl:mt-16`}
          >
            sunday & monday: <span className={`text-primary`}>closed</span>
          </h3>
          <h3
              className={`text-center text-white font-teko text-3xl xl:text-4xl mt-2 lg:mt-4 xl:mt-6`}
          >
            <span className={`text-primary`}>closed for lunch: </span>14:00 - 15:00
          </h3>
        </div>
      </div>

      <Footer />
    </div>
  );
}
