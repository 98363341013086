import React, { useState } from "react";
import Slider from "react-slick";

export default function Reviews() {
  const [sliderRef, setSliderRef] = useState(null);

  var settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div
      className={`relative w-11/12 sm:w-11/12 mx-auto`}
    >
      <button
        onClick={sliderRef?.slickPrev}
        className={`z-10 absolute top-1/2 left-0 md:left-[40px] lg:left-[110xspx] xl:left-[125px] bg-white rounded-full p-1.5`}
      >
        <div className={``}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="#fff"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
        </div>
      </button>
      <Slider
        ref={setSliderRef}
        {...settings}
        className={`reviews bg-dark w-9/12 sm:w-9/12 mx-auto`}
      >
        <div
          className={`flex flex-col border xl:border-2 border-primary rounded-lg bg-[#2a2a2a] p-5 xl:p-7 space-y-2.5 xl:space-y-4 `}
        >
          <div className={`text-[#cbc4b1]`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5 xl:w-6 xl:h-6"
              viewBox="0 0 351.128 351.128"
              xmlSpace="preserve"
              fill="#fff"
            >
              <path d="M72.326 147.33c4.284-26.928 37.944-55.692 64.26-56.304 1.836 0 3.672-.612 4.896-1.836 1.224-.612 2.448-1.224 3.06-3.06 9.18-17.136 4.284-30.6-11.016-41.616-17.748-12.852-45.9 0-59.976 11.628-35.496 29.376-71.604 80.171-69.768 128.52-6.12 32.437-4.896 67.32 4.284 96.084 6.12 18.36 23.868 27.54 42.228 28.764 18.36 1.225 56.304 6.732 72.828-4.283 16.524-11.017 17.748-32.437 19.584-50.796 1.836-20.196 7.344-58.141-9.792-74.053-17.136-15.3-66.096 1.224-60.588-33.048zM274.286 147.33c4.284-26.928 37.943-55.692 64.26-56.304 1.836 0 3.672-.612 4.896-1.836 1.225-.612 2.448-1.224 3.061-3.06 9.18-17.136 4.284-30.6-11.016-41.616-17.748-12.852-45.9 0-59.977 11.628-35.496 29.376-71.604 80.172-69.768 128.52-6.12 32.437-4.896 67.32 4.283 96.084 6.12 18.36 23.868 27.54 42.229 28.764 18.36 1.225 56.304 6.732 72.828-4.283 16.523-11.017 17.748-32.437 19.584-50.796 1.836-20.196 7.344-58.141-9.792-74.053-17.136-15.3-66.708 1.224-60.588-33.048z"></path>
            </svg>
          </div>
          <p
            className={`text-xs xl:text-sm text-white leading-loose xl:leading-[2rem]`}
          >
            The best barber shop experience I have ever had. The barber was incredibly friendly and made me feel right at home as soon as I walked in. He took the time to listen to what I wanted and made sure that I was completely satisfied with my haircut. The attention to detail was outstanding and the end result was a perfect haircut. The shop was clean and well-maintained, and the overall atmosphere was inviting and relaxing. I highly recommend this barber shop to anyone looking for a great haircut and a friendly, welcoming atmosphere.
          </p>
          {/*<h1 className={`font-bold text-xs xl:text-sm text-white`}>*/}
          {/*  - O.G*/}
          {/*</h1>*/}
        </div>
        <div
          className={` flex flex-col border xl:border-2 border-primary rounded-lg bg-[#2a2a2a] p-5 xl:p-7 space-y-2.5 xl:space-y-4`}
        >
          <div className={`text-white`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5 xl:w-6 xl:h-6"
              viewBox="0 0 351.128 351.128"
              xmlSpace="preserve"
              fill="#fff"
            >
              <path d="M72.326 147.33c4.284-26.928 37.944-55.692 64.26-56.304 1.836 0 3.672-.612 4.896-1.836 1.224-.612 2.448-1.224 3.06-3.06 9.18-17.136 4.284-30.6-11.016-41.616-17.748-12.852-45.9 0-59.976 11.628-35.496 29.376-71.604 80.171-69.768 128.52-6.12 32.437-4.896 67.32 4.284 96.084 6.12 18.36 23.868 27.54 42.228 28.764 18.36 1.225 56.304 6.732 72.828-4.283 16.524-11.017 17.748-32.437 19.584-50.796 1.836-20.196 7.344-58.141-9.792-74.053-17.136-15.3-66.096 1.224-60.588-33.048zM274.286 147.33c4.284-26.928 37.943-55.692 64.26-56.304 1.836 0 3.672-.612 4.896-1.836 1.225-.612 2.448-1.224 3.061-3.06 9.18-17.136 4.284-30.6-11.016-41.616-17.748-12.852-45.9 0-59.977 11.628-35.496 29.376-71.604 80.172-69.768 128.52-6.12 32.437-4.896 67.32 4.283 96.084 6.12 18.36 23.868 27.54 42.229 28.764 18.36 1.225 56.304 6.732 72.828-4.283 16.523-11.017 17.748-32.437 19.584-50.796 1.836-20.196 7.344-58.141-9.792-74.053-17.136-15.3-66.708 1.224-60.588-33.048z"></path>
            </svg>
          </div>
          <p
            className={`text-xs xl:text-sm text-white leading-loose xl:leading-[2rem]`}
          >
            Einfach top! Super freundlich und immer sehr bemüht.
            Haare und Bart schauen immer perfekt aus. Kann ich nur empfehlen.
          </p>
          {/*<h1 className={`font-bold text-xs xl:text-sm text-white`}>*/}
          {/*  - STEFAN*/}
          {/*</h1>*/}
        </div>
        <div
          className={`flex flex-col border xl:border-2 border-primary rounded-lg bg-[#2a2a2a] p-5 xl:p-7 space-y-2.5 xl:space-y-4`}
        >
          <div className={`text-white`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5 xl:w-6 xl:h-6"
              viewBox="0 0 351.128 351.128"
              xmlSpace="preserve"
              fill="#fff"
            >
              <path d="M72.326 147.33c4.284-26.928 37.944-55.692 64.26-56.304 1.836 0 3.672-.612 4.896-1.836 1.224-.612 2.448-1.224 3.06-3.06 9.18-17.136 4.284-30.6-11.016-41.616-17.748-12.852-45.9 0-59.976 11.628-35.496 29.376-71.604 80.171-69.768 128.52-6.12 32.437-4.896 67.32 4.284 96.084 6.12 18.36 23.868 27.54 42.228 28.764 18.36 1.225 56.304 6.732 72.828-4.283 16.524-11.017 17.748-32.437 19.584-50.796 1.836-20.196 7.344-58.141-9.792-74.053-17.136-15.3-66.096 1.224-60.588-33.048zM274.286 147.33c4.284-26.928 37.943-55.692 64.26-56.304 1.836 0 3.672-.612 4.896-1.836 1.225-.612 2.448-1.224 3.061-3.06 9.18-17.136 4.284-30.6-11.016-41.616-17.748-12.852-45.9 0-59.977 11.628-35.496 29.376-71.604 80.172-69.768 128.52-6.12 32.437-4.896 67.32 4.283 96.084 6.12 18.36 23.868 27.54 42.229 28.764 18.36 1.225 56.304 6.732 72.828-4.283 16.523-11.017 17.748-32.437 19.584-50.796 1.836-20.196 7.344-58.141-9.792-74.053-17.136-15.3-66.708 1.224-60.588-33.048z"></path>
            </svg>
          </div>
          <p
            className={`text-xs xl:text-sm text-white leading-loose xl:leading-[2rem]`}
          >
            Ich gehe nun schon seit geraumer Zeit zum Jägers Barbershop und bin so zufrieden wie noch nie bei einem Friseur/Barber. Es wird auf die Wünsche und Vorstellungen eingegangen und gleichzeitig aber auch darauf geachtet, dass der Schnitt einem passt! Top Schnitte, Top Betreuung und immer ein nettes Gespräch. Mehr kann man sich nicht wünschen und deswegen sage ich: DANKE Emanuel!
          </p>
          {/*<h1 className={`font-bold text-xs xl:text-sm text-white`}>*/}
          {/*  - LUCIANO*/}
          {/*</h1>*/}
        </div>
        <div
          className={`flex flex-col border xl:border-2 border-primary rounded-lg bg-[#2a2a2a] p-5 xl:p-7 space-y-2.5 xl:space-y-4`}
        >
          <div className={`text-white`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5 xl:w-6 xl:h-6"
              viewBox="0 0 351.128 351.128"
              xmlSpace="preserve"
              fill="#fff"
            >
              <path d="M72.326 147.33c4.284-26.928 37.944-55.692 64.26-56.304 1.836 0 3.672-.612 4.896-1.836 1.224-.612 2.448-1.224 3.06-3.06 9.18-17.136 4.284-30.6-11.016-41.616-17.748-12.852-45.9 0-59.976 11.628-35.496 29.376-71.604 80.171-69.768 128.52-6.12 32.437-4.896 67.32 4.284 96.084 6.12 18.36 23.868 27.54 42.228 28.764 18.36 1.225 56.304 6.732 72.828-4.283 16.524-11.017 17.748-32.437 19.584-50.796 1.836-20.196 7.344-58.141-9.792-74.053-17.136-15.3-66.096 1.224-60.588-33.048zM274.286 147.33c4.284-26.928 37.943-55.692 64.26-56.304 1.836 0 3.672-.612 4.896-1.836 1.225-.612 2.448-1.224 3.061-3.06 9.18-17.136 4.284-30.6-11.016-41.616-17.748-12.852-45.9 0-59.977 11.628-35.496 29.376-71.604 80.172-69.768 128.52-6.12 32.437-4.896 67.32 4.283 96.084 6.12 18.36 23.868 27.54 42.229 28.764 18.36 1.225 56.304 6.732 72.828-4.283 16.523-11.017 17.748-32.437 19.584-50.796 1.836-20.196 7.344-58.141-9.792-74.053-17.136-15.3-66.708 1.224-60.588-33.048z"></path>
            </svg>
          </div>
          <p
            className={`text-xs xl:text-sm text-white leading-loose xl:leading-[2rem]`}
          >
            The number one barber in Vienna. Doesn't matter if it's
            for a job interview, wedding, or whatever: I would always trust Emanuel with my haircut. Great service, great atmosphere and great coffee.
          </p>
          {/*<h1 className={`font-bold text-xs xl:text-sm text-white`}>*/}
          {/*  - LUCIANO*/}
          {/*</h1>*/}
        </div>
      </Slider>
      <button
        onClick={sliderRef?.slickNext}
        className={`absolute top-1/2 right-0 md:right-[40px] lg:right-[110xspx] xl:right-[125px] bg-white rounded-full p-1.5`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M14 5l7 7m0 0l-7 7m7-7H3"
          />
        </svg>
      </button>
    </div>
  );
}
