import Navbar from "./Navbar";
import Footer from "./Footer";
import blackM from "../img/blackM.png";
import icon1 from "../img/icon1.png";
import icon2 from "../img/icon2.png";
import icon3 from "../img/icon3.png";
import whiteM from "../img/whiteM.png";
import logo from "../img/logo.svg";
import React from "react";

export default function Services() {
  return (
    <div>
      <Navbar />

      {/*header*/}
      <div
        className={`relative -z-20 bg-black flex flex-col mx-auto items-center py-12 s:py-16 sm:py-20 xl:py-24 space-y-3 xl:space-y-5`}
      >
        <img
          className={`-z-10 opacity-20 absolute w-24 w-[150px] lg:w-[200px] xl:w-[300px] top-1/2 -translate-y-1/2`}
          src={logo}
          alt=""
        />

        <h1
          className={`z-0 uppercase text-white font-teko text-center text-5xl xl:text-6xl`}
        >
          our services
        </h1>
      </div>

      {/*section 1*/}
      <div className={`bg-[#f4f2eb]`}>
        <div className={`px-4 xs:px-6 md:px-0 py-12 lg:py-20 xl:py-24`}>
          <div
            className={`flex flex-col items-center text-center mx-auto space-y-3 xl:space-y-5`}
          >
            <h1
              className={`font-teko text-5xl xl:text-6xl font-medium text-dark`}
            >
              SERVICE MENU
            </h1>
            <img className={`w-40 xl:w-48`} src={blackM} alt="" />
          </div>

          <div
            className={`mt-6 xs:mt-8 lg:mt-10 xl:mt-14 mx-auto flex flex-col md:flex-row items-center justify-center space-y-6 md:space-y-0 md:space-x-4 lg:space-x-6 xl:space-x-8`}
          >
            <div
              className={`h-[370px]  md:h-[390px] lg:h-[400px] xl:h-[480px] bg-[#f4f2eb] rounded-lg xl:rounded-xl border-2 border-primary pt-4 lg:pt-6 xl:pt-7 px-4 lg:px-6 xl:px-7`}
            >
              <div
                className={`flex flex-col  items-center w-[220px] md:w-48 lg:w-50 xl:w-60`}
              >
                <img className={`w-20 xl:w-24 ml-4`} src={icon1} alt="" />
                <ul className={`w-full`}>
                  <li
                    className={`mt-3 xl:mt-5 text-xs xl:text-sm clear-both m-0 pb-[1.8em] xl:pb-[2em] relative border-[#777777] border-b-2 border-dotted text-dark`}
                  >
                    <span
                      className={`bg-[#f4f2eb]  absolute bottom-[-.3em] xl:bottom-[-.4em] left-0 pr-1 font-bold`}
                    >
                      CLASSIC HAIRCUT
                    </span>{" "}
                    <span
                      className={`bg-[#f4f2eb] absolute bottom-[-.3em] xl:bottom-[-.4em] right-0 pl-1 font-bold text-primary`}
                    >
                      €37
                    </span>
                  </li>
                  <h1
                    className={`text-[11px] xl:text-xs text-[#666565] mt-2 xl:mt-3`}
                  ></h1>
                  <li
                    className={`mt-3 xl:mt-5 text-xs xl:text-sm clear-both m-0 pb-[1.8em] xl:pb-[2em] relative border-[#777777] border-b-2 border-dotted text-dark`}
                  >
                    <span
                      className={`bg-[#f4f2eb]  absolute bottom-[-.3em] left-0 pr-1 font-bold`}
                    >
                      HEAD HOT TOWEL SHAVE
                    </span>{" "}
                    <span
                      className={`bg-[#f4f2eb] absolute bottom-[-.3em] xl:bottom-[-.4em] right-0 pl-1 font-bold text-primary`}
                    >
                      €35
                    </span>
                  </li>
                  <h1
                    className={` text-[11px] xl:text-xs text-[#666565] mt-2 xl:mt-3`}
                  ></h1>
                  <li
                    className={`mt-3 xl:mt-5 text-xs xl:text-sm clear-both m-0 pb-[1.8em] xl:pb-[2em] relative border-[#777777] border-b-2 border-dotted text-dark`}
                  >
                    <span
                      className={`bg-[#f4f2eb]  absolute bottom-[-.3em] left-0 pr-1 font-bold`}
                    >
                      ONE LENGTH
                    </span>{" "}
                    <span
                      className={`bg-[#f4f2eb] absolute bottom-[-.3em] xl:bottom-[-.4em] right-0 pl-1 font-bold text-primary`}
                    >
                      €25
                    </span>
                  </li>
                  <h1
                    className={`text-[11px] xl:text-xs text-[#666565] mt-2 xl:mt-3`}
                  ></h1>
                  <li
                    className={`mt-3 xl:mt-5 text-xs xl:text-sm clear-both m-0 pb-[1.8em] xl:pb-[2em] relative border-[#777777] border-b-2 border-dotted text-dark`}
                  >
                    <span
                      className={`bg-[#f4f2eb]  absolute bottom-[-.3em] left-0 pr-1 font-bold`}
                    >
                      THE 45' MIN HAIRCUT
                    </span>{" "}
                    <span
                      className={`bg-[#f4f2eb] absolute bottom-[-.3em] xl:bottom-[-.4em] right-0 pl-1 font-bold text-primary`}
                    >
                      €47
                    </span>
                  </li>
                  <h1
                    className={`text-[11px] xl:text-xs text-[#666565] mt-2 xl:mt-3`}
                  ></h1>
                </ul>
              </div>
            </div>

            <div
              className={`h-[370px]  md:h-[390px] lg:h-[400px] xl:h-[480px] bg-[#f4f2eb] rounded-lg  xl:rounded-xl border-2 border-primary pt-4 lg:pt-6 xl:pt-7 px-4 lg:px-6 xl:px-7`}
            >
              <div
                className={`flex flex-col items-center w-[220px] md:w-48 lg:w-50 xl:w-60`}
              >
                <img className={`w-20 xl:w-24 ml-4`} src={icon2} alt="" />
                <ul className={`w-full`}>
                  <li
                    className={`mt-3 xl:mt-5 text-xs xl:text-sm clear-both m-0 pb-[1.8em] xl:pb-[2em] relative border-[#777777] border-b-2 border-dotted text-dark`}
                  >
                    <span
                      className={`bg-[#f4f2eb]  absolute bottom-[-.3em] xl:bottom-[-.4em] left-0 pr-1 font-bold`}
                    >
                      BEARD TRIM
                    </span>{" "}
                    <span
                      className={`bg-[#f4f2eb] absolute bottom-[-.3em] xl:bottom-[-.4em] right-0 pl-1 font-bold text-primary`}
                    >
                      €35
                    </span>
                  </li>
                  <h1
                    className={`text-[11px] xl:text-xs text-[#666565] mt-2 xl:mt-3`}
                  ></h1>
                  <li
                    className={`mt-3 xl:mt-5 text-xs xl:text-sm clear-both m-0 pb-[1.8em] xl:pb-[2em] relative border-[#777777] border-b-2 border-dotted text-dark`}
                  >
                    <span
                      className={`bg-[#f4f2eb]  absolute bottom-[-.3em] left-0 pr-1 font-bold`}
                    >
                      HOT TOWEL SHAVE
                    </span>{" "}
                    <span
                      className={`bg-[#f4f2eb] absolute bottom-[-.3em] xl:bottom-[-.4em] right-0 pl-1 font-bold text-primary`}
                    >
                      €35
                    </span>
                  </li>
                  <h1
                    className={` text-[11px] xl:text-xs text-[#666565] mt-2 xl:mt-3`}
                  ></h1>
                </ul>
              </div>
            </div>

            <div
              className={`h-[370px]  md:h-[390px] lg:h-[400px] xl:h-[480px] bg-[#f4f2eb] rounded-lg xl:rounded-xl border-2 border-primary pt-4 lg:pt-6 xl:pt-7 px-4 lg:px-6 xl:px-7`}
            >
              <div
                className={`flex flex-col  items-center w-[220px] md:w-48 lg:w-50 xl:w-60 `}
              >
                <img className={`w-20 xl:w-24 ml-4`} src={icon3} alt="" />
                <ul className={`w-full mt-6`}>
                  <li
                    className={`text-xs xl:text-sm clear-both m-0 pb-[1.8em] xl:pb-[2em] relative border-[#777777] border-b-2 border-dotted text-dark`}
                  >
                    <span
                      className={`bg-[#f4f2eb]  absolute bottom-[-.3em] xl:bottom-[-.4em] left-0 pr-1 font-bold`}
                    >
                      COMBO HAIRCUT <br /> + BEARD TRIM
                    </span>{" "}
                    <span
                      className={`bg-[#f4f2eb] absolute bottom-[-.3em] xl:bottom-[-.4em] right-0 pl-1 font-bold text-primary`}
                    >
                      €70
                    </span>
                  </li>
                  <h1
                    className={`text-[11px] xl:text-xs text-[#666565] mt-2 xl:mt-3`}
                  ></h1>
                  <li
                    className={`mt-6 text-xs xl:text-sm clear-both m-0 pb-[1.8em] xl:pb-[2em] relative border-[#777777] border-b-2 border-dotted text-dark`}
                  >
                    <span
                      className={`bg-[#f4f2eb]  absolute bottom-[-.3em] left-0 pr-1 font-bold`}
                    >
                      COMBO HAIRCUT <br/> + SHAVE
                    </span>{" "}
                    <span
                      className={`bg-[#f4f2eb] absolute bottom-[-.3em] xl:bottom-[-.4em] right-0 pl-1 font-bold text-primary`}
                    >
                      €70
                    </span>
                  </li>
                  <h1
                    className={` text-[11px] xl:text-xs text-[#666565] mt-2 xl:mt-3`}
                  ></h1>
                  <li
                    className={`mt-6  text-xs xl:text-sm clear-both m-0 pb-[1.8em] xl:pb-[2em] relative border-[#777777] border-b-2 border-dotted text-dark`}
                  >
                    <span
                      className={`bg-[#f4f2eb]  absolute bottom-[-.3em] left-0 pr-1 font-bold`}
                    >
                      FATHER & SON <br/> HAIRCUT
                    </span>{" "}
                    <span
                      className={`bg-[#f4f2eb] absolute bottom-[-.3em] xl:bottom-[-.4em] right-0 pl-1 font-bold text-primary`}
                    >
                      €70
                    </span>
                  </li>
                  <h1
                    className={`text-[11px] xl:text-xs text-[#666565] mt-2 xl:mt-3`}
                  ></h1>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*section 2*/}
      <div className={`bg-[#272727]`}>
        <div
          className={`2xl:container mx-auto py-12 lg:py-16 xl:py-20 flex flex-col items-center uppercase`}
        >
          <h1
            className={`text-white font-teko text-5xl xl:text-6xl font-medium `}
          >
            working hours
          </h1>

            <h2
              className={`text-white font-teko text-3xl lg:text-5xl xl:text-6xl mt-4`}
            >
              <span className={`text-primary`}>call us:</span> <a href="tel:+4318908780"> +4318908780 </a>
            </h2>{" "}

          <div
            className={`grid grid-cols-2 s:grid-cols-3 gap-3 s:gap-4 md:gap-6 lg:gap-0 lg:flex lg:flex-row lg:space-x-4 xl:space-x-6 mt-8 lg:mt-10 xl:mt-14`}
          >
            <div
              className={`text-center rounded-lg border-2 border-primary text-white px-2.5 xl:px-3.5 py-3 xl:py-4 bg-[#2a2a2a]`}
            >
              <h1 className={`text-3xl xl:text-4xl font-teko`}>TUES</h1>
              <h2 className={`text-[11px] xl:text-xs font-bold`}>
                10.00 AM - 7.00 PM
              </h2>
            </div>
            <div
              className={`text-center rounded-lg border-2 border-primary text-white px-2.5 xl:px-3.5 py-3 xl:py-4 bg-[#2a2a2a]`}
            >
              <h1 className={`text-3xl xl:text-4xl font-teko`}>WED</h1>
              <h2 className={`text-[11px] xl:text-xs font-bold`}>
                10.00 AM - 7.00 PM
              </h2>
            </div>
            <div
              className={`text-center rounded-lg border-2 border-primary text-white px-2.5 xl:px-3.5 py-3 xl:py-4 bg-[#2a2a2a]`}
            >
              <h1 className={`text-3xl xl:text-4xl font-teko`}>THUR</h1>
              <h2 className={`text-[11px] xl:text-xs font-bold`}>
                10.00 AM - 7.00 PM
              </h2>
            </div>
            <div
              className={`text-center rounded-lg border-2 border-primary text-white px-2.5 xl:px-3.5 py-3 xl:py-4 bg-[#2a2a2a]`}
            >
              <h1 className={`text-3xl xl:text-4xl font-teko`}>FRI</h1>
              <h2 className={`text-[11px] xl:text-xs font-bold`}>
                9.00 AM - 7.00 PM
              </h2>
            </div>
            <div
              className={`text-center rounded-lg border-2 border-primary text-white px-2.5 xl:px-3.5 py-3 xl:py-4 bg-[#2a2a2a]`}
            >
              <h1 className={`text-3xl xl:text-4xl font-teko`}>SAT</h1>
              <h2 className={`text-[11px] xl:text-xs font-bold`}>
                9.00 AM - 5.00 PM
              </h2>
            </div>
          </div>
          <h3
            className={`text-center text-white font-teko text-3xl xl:text-4xl mt-6 lg:mt-12 xl:mt-16`}
          >
            sunday & monday: <span className={`text-primary`}>closed</span>
          </h3>
          <h3
            className={`text-center text-white font-teko text-3xl xl:text-4xl mt-2 lg:mt-4 xl:mt-6`}
          >
            <span className={`text-primary`}>closed for lunch: </span>14:00 -
            15:00
          </h3>
        </div>
      </div>

      <Footer />
    </div>
  );
}
