import Sidebar from "./Sidebar";
import pic from "../../img/handsome-man-barber-shop-styling-hair.jpg";
import { Fragment, useEffect, useRef, useState } from "react";
import axios from "axios";
import { getToken } from "../helpers/auth";
import { Dialog, Transition } from "@headlessui/react";
import React from "react";
import { Listbox } from "@headlessui/react";
import { ImageViewer } from "react-image-viewer-dv";

export default function AD_Gallery() {
  const cancelButtonRef = useRef(null);
  const [addModal, setAddModal] = useState(false);
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState();
  const [selected, setSelected] = useState(categories[0]);
  const handleChangeCategory = (event) => {
    setCategoryId(event.target.value);
  };

  useEffect(() => {
    getData();
    getCategories();
  }, []);

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/gallery`, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {});
  };

  const getCategories = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/gallery/categories`, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {});
  };

  const [formData, setFormData] = useState({
    file: "",
    categoryId: "",
  });

  const { file } = formData;

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("categoryId", categoryId);
    axios
      .post(`${process.env.REACT_APP_API_URL}/user/add/photo`, formData, {
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((result) => {
        const new_image = result.data;
        setData([...data, new_image]);
        setAddModal(false);
        setImagePreviewSrc(false);
      })
      .catch((err) => {});
  };

  const [isImageSelected, setIsImageSelected] = useState(false);
  const [imagePreviewSrc, setImagePreviewSrc] = useState("");

  const showImagePreview = (e) => {
    let selectedFile = e.target.files.item(0);
    if (selectedFile) {
      if (
        ["image/jpeg", "image/png", "image/svg+xml"].includes(selectedFile.type)
      ) {
        let fileReader = new FileReader();
        fileReader.readAsDataURL(selectedFile);
        fileReader.addEventListener("load", (event) => {
          setImagePreviewSrc(event.target.result);
          setFormData({ ...formData, ["file"]: e.target.files.item(0) });
          setIsImageSelected(true);
        });
      }
    } else {
      setIsImageSelected(false);
    }
  };

  const deleteImage = ({ dataId }) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/user/delete/photo/${dataId}`,

        {
          headers: {
            Authorization: "Bearer " + getToken(),
          },
        }
      )
      .then((res) => {
        const index_obj = data.findIndex(
          (photo) => photo._id === res.data?._id
        );
        data.splice(index_obj, 1);
        setData([...data]);
      });
  };

  return (
    <div>
      <div className={`bg-[#f4f2eb] relative  md:flex flex-row `}>
        <Sidebar />
        <div className={`2xl:container 2xl:mx-auto pt-10`}>
          <div className={`flex flex-row justify-end px-6`}>
            <svg
              onClick={() => {
                setAddModal(true);
              }}
              className={`w-12 h-12 `}
              viewBox="0 0 159 159"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M108.07 74.5312H84.4688V50.9297C84.4688 50.2465 83.9098 49.6875 83.2266 49.6875H75.7734C75.0902 49.6875 74.5312 50.2465 74.5312 50.9297V74.5312H50.9297C50.2465 74.5312 49.6875 75.0902 49.6875 75.7734V83.2266C49.6875 83.9098 50.2465 84.4688 50.9297 84.4688H74.5312V108.07C74.5312 108.754 75.0902 109.312 75.7734 109.312H83.2266C83.9098 109.312 84.4688 108.754 84.4688 108.07V84.4688H108.07C108.754 84.4688 109.312 83.9098 109.312 83.2266V75.7734C109.312 75.0902 108.754 74.5312 108.07 74.5312Z"
                fill="#808080"
              />
              <path
                d="M79.5 9.9375C41.0854 9.9375 9.9375 41.0854 9.9375 79.5C9.9375 117.915 41.0854 149.062 79.5 149.062C117.915 149.062 149.062 117.915 149.062 79.5C149.062 41.0854 117.915 9.9375 79.5 9.9375ZM79.5 137.262C47.6068 137.262 21.7383 111.393 21.7383 79.5C21.7383 47.6068 47.6068 21.7383 79.5 21.7383C111.393 21.7383 137.262 47.6068 137.262 79.5C137.262 111.393 111.393 137.262 79.5 137.262Z"
                fill="#808080"
              />
            </svg>
          </div>
          <Transition.Root show={addModal} as={Fragment}>
            <Dialog
              as="div"
              className="fixed z-30 inset-0 overflow-y-auto"
              initialFocus={cancelButtonRef}
              onClose={(e) => {
                setAddModal(e);
              }}
            >
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-150"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-150"
                  leaveFrom="opacity-70"
                  leaveTo="opacity-0"
                >
                  <Dialog.Overlay className="fixed inset-0 backdrop-filter backdrop-blur-sm bg-gray-300 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                  className="hidden sm:inline-block sm:align-middle sm:h-screen"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-150"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className="inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-lg transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
                    <div className="bg-[#f4f2eb] relative w-full py-10 ">
                      <div className="flex row py-2 px-12">
                        <div className="relative inline-block w-full text-gray-700 ">
                          <select
                            className="w-full py-2 pl-3 pr-6 text-base placeholder-gray-600 border rounded-xl appearance-none focus:shadow-outline outline-none"
                            onChange={handleChangeCategory}
                            value={selected}
                          >
                            {categories.map((categories) => (
                              <option
                                key={categories._id}
                                value={categories._id}
                              >
                                {" "}
                                {categories.name}
                              </option>
                            ))}
                          </select>

                          <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                            <svg
                              className="w-4 h-4 fill-current"
                              viewBox="0 0 20 20"
                            >
                              <path
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                                fill-rule="evenodd"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <form
                        onSubmit={handleSubmit}
                        className={`mt-4 px-10 flex flex-col sm:flex-row sm:space-x-6 justify-center items-center`}
                      >
                        <div className={`order-last sm:order-first`}>
                          <input
                            className={`hidden`}
                            type="file"
                            multiple
                            onChange={showImagePreview}
                            id="file"
                          />
                          <div
                            className={` w-56 lg:w-64 poppins-regular text-sm xl:text-base font-bold text-lightgray text-center py-3  rounded-2xl  border-lightgray border-2 mt-3 xl:mt-6`}
                          >
                            <label htmlFor="file">Choose Photo</label>
                          </div>
                          <button
                            type="submit"
                            className={`${
                              isImageSelected ? "block" : "hidden"
                            } w-56 lg:w-64 poppins-regular text-sm font-bold bg-primary text-white text-center py-3 rounded-2xl  border-primary border-2 mt-3 xl:mt-6`}
                          >
                            Add photo
                          </button>
                        </div>
                        {isImageSelected ? (
                          <img
                            className={`mt-4 mb-2 w-56 h-56 lg:w-64 lg:h-64`}
                            src={imagePreviewSrc}
                            alt=""
                          />
                        ) : null}
                      </form>
                    </div>
                  </div>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          <div
            className={`py-10 px-10 justify-center  flex flex-row flex-wrap gap-4 sm:gap-8`}
          >
            {data.map((data) => (
              <div key={data._id} className={`relative  group`}>
                <ImageViewer>
                  <img
                    className={`w-56 h-56 lg:w-64 lg:h-64`}
                    src={data.image}
                    alt=""
                  />
                </ImageViewer>
                <button
                  onClick={() => {
                    deleteImage({ dataId: data._id });
                  }}
                >
                  <svg
                    className={`absolute top-3 right-3 w-5 h-5`}
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.645917 0.646039C0.692363 0.599476 0.747538 0.562533 0.808283 0.537326C0.869028 0.51212 0.93415 0.499146 0.999917 0.499146C1.06568 0.499146 1.13081 0.51212 1.19155 0.537326C1.2523 0.562533 1.30747 0.599476 1.35392 0.646039L3.99992 3.29304L6.64592 0.646039C6.6924 0.599551 6.74759 0.562675 6.80833 0.537516C6.86907 0.512357 6.93417 0.499407 6.99992 0.499407C7.06566 0.499407 7.13076 0.512357 7.1915 0.537516C7.25224 0.562675 7.30743 0.599551 7.35392 0.646039C7.40041 0.692527 7.43728 0.747716 7.46244 0.808455C7.4876 0.869195 7.50055 0.934295 7.50055 1.00004C7.50055 1.06578 7.4876 1.13088 7.46244 1.19162C7.43728 1.25236 7.40041 1.30755 7.35392 1.35404L4.70692 4.00004L7.35392 6.64604C7.40041 6.69253 7.43728 6.74772 7.46244 6.80846C7.4876 6.86919 7.50055 6.9343 7.50055 7.00004C7.50055 7.06578 7.4876 7.13088 7.46244 7.19162C7.43728 7.25236 7.40041 7.30755 7.35392 7.35404C7.30743 7.40053 7.25224 7.4374 7.1915 7.46256C7.13076 7.48772 7.06566 7.50067 6.99992 7.50067C6.93417 7.50067 6.86907 7.48772 6.80833 7.46256C6.74759 7.4374 6.6924 7.40053 6.64592 7.35404L3.99992 4.70704L1.35392 7.35404C1.30743 7.40053 1.25224 7.4374 1.1915 7.46256C1.13076 7.48772 1.06566 7.50067 0.999917 7.50067C0.934173 7.50067 0.869073 7.48772 0.808333 7.46256C0.747594 7.4374 0.692405 7.40053 0.645917 7.35404C0.599429 7.30755 0.562553 7.25236 0.537394 7.19162C0.512234 7.13088 0.499285 7.06578 0.499285 7.00004C0.499285 6.9343 0.512234 6.86919 0.537394 6.80846C0.562553 6.74772 0.599429 6.69253 0.645917 6.64604L3.29292 4.00004L0.645917 1.35404C0.599354 1.30759 0.562411 1.25242 0.537204 1.19167C0.511998 1.13093 0.499023 1.06581 0.499023 1.00004C0.499023 0.934272 0.511998 0.869151 0.537204 0.808405C0.562411 0.74766 0.599354 0.692485 0.645917 0.646039Z"
                      fill="#F8F8F8"
                    />
                  </svg>
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
