import { useParams } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import { getToken } from "../helpers/auth";
import JoditEditor from "jodit-react";
import { useNavigate } from "react-router-dom";

export default function AD_BlogDetails() {
  const navigate = useNavigate();
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [placeholder, setPlaceholder] = useState();
  const config = useCallback(
    {
      readonly: false, // all options from https://xdsoft.net/jodit/doc/,
      placeholder: placeholder || "Start typings...",
    },
    [placeholder]
  );

  const { id } = useParams();
  const [data, setData] = useState({
    description: "",
    title: "",
    image: "",
  });

  const { title, image, description } = data;

  useEffect(() => {
    getData();
  }, [id]);

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/blog/${id}`, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
      .then((res) => {
        const { title, description, image } = res.data;
        setData({ ...data, title, description, image });
        setContent(description);
      })
      .catch((err) => {});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/user/update/blog/${id}`,
        {
          title: title,
          description: content,
        },
        {
          headers: {
            Authorization: "Bearer " + getToken(),
          },
        }
      )
      .then((result) => {
        navigate(`/admin/press`);
      })
      .catch((err) => {});
  };

  const handleChange = (text) => (e) => {
    setData({ ...data, [text]: e.target.value });
  };

  const handleImage = ({ image }) => {
    const data = new FormData();
    data.append("file", image);

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/user/update/blog/image/${id}`,
        data,
        {
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((result) => {
        setData({ ...data, ["image"]: result.data.image });
      })
      .catch((err) => {});
  };

  const removeBlog = (event) => {
    event.preventDefault();
    axios
      .delete(`${process.env.REACT_APP_API_URL}/user/delete/blog/${id}`, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
      .then((result) => {
        navigate(`/admin/press`);
      })
      .catch((err) => {});
  };

  return (
      <div>
        <div className={`bg-[#f4f2eb] relative  md:flex flex-row `}>
          <Sidebar />

          {/*article*/}
          <form
              onSubmit={handleSubmit}
              className={`w-full mx-auto md:w-3/5 px-4 xs:px-6 md:px-12 py-12`}
          >
            <div className={`w-full relative`}>
              <div className={`h-48 xs:h-56 s:h-60 sm:h-72  lg:h-96 xl:h-[500px]`}>
                <label htmlFor="file">
                  <input
                      id="file"
                      multiple
                      className={`hidden`}
                      type="file"
                      onChange={(e) => {
                        handleImage({ image: e.target.files[0] });
                      }}
                  />

                  <div className={`w-full h-full bg-center bg-cover`} style={{ backgroundImage: `url(${image})` }} />
                </label>
              </div>
              <div className={` py-6 lg:py-8`}>
                <input
                    onChange={handleChange("title")}
                    value={title}
                    placeholder={title}
                    className={`py-2 px-4  bg-transparent border border-lightgray w-full my-4 font-teko font-medium text-darker text-2xl lg:text-4xl`}
                />

                <JoditEditor
                    ref={editor}
                    value={content}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                    onChange={(newContent) => {}}
                />
              </div>
            </div>
            <div className={`flex flex-row gap-6`}>
              <button
                  type="submit"
                  className={`font-teko bg-primary rounded-2xl  pt-1  w-32 text-white text-2xl uppercase`}
              >
                {" "}
                save
              </button>
              <button
                  onClick={removeBlog}
                  className={`font-teko bg-transparent border border-dark rounded-2xl pt-1  w-32  text-dark text-2xl uppercase`}
              >
                {" "}
                delete
              </button>
            </div>
          </form>
        </div>
      </div>
  );
}
